<template><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" height="12" width="12"><title>{{title}}</title><g :fill="_secondaryfill"><path d="M11,9H1a1,1,0,0,0,0,2H11a1,1,0,0,0,0-2Z" :fill="fill"></path> <path d="M11,1H1A1,1,0,0,0,1,3H11a1,1,0,0,0,0-2Z" :fill="fill"></path> <path d="M11,5H1A1,1,0,0,0,1,7H11a1,1,0,0,0,0-2Z"></path></g></svg></template>

<script>
export default {
	props: {
		title: {
			type: String,
			default: "menu 8"
		},
		fill: {
			type: String,
			default: "currentColor"
		},
		secondaryfill: {
			type: String
		}
	},
	computed: {
		_secondaryfill: function () {
			return this.secondaryfill || this.fill
		}
	}
}
</script>