<template>
    <tutorial></tutorial>
</template>
<script>
import Tutorial from "@/components/Tutorial.vue";

export default {
  components: { Tutorial },
    
}
</script>
<style>

</style>