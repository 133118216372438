<template>
<div class="loader" id="loadingvue">
    <b-container>
      <b-row class="vh-100" align-v="center">
        <b-col cols="6" offset="2" md="2" class="logocol">
          <b-img class="logoload" :src="require('../assets/Logo_Elettra_Social_bianco_v2.png')" alt="Logo Elettra" fluid></b-img>
        </b-col>
        <b-col cols="8" offset="2" md="6" offset-md="0" class="isocol">
          <h1>Elettra Sincrotrone Trieste Virtual Visit</h1>
          <b-progress :value="loadingValue" :max="1" class="mb-3" height=2px></b-progress>
        </b-col>
      </b-row>
      <b-row >
      </b-row>
    </b-container>
</div>
</template>
<script>
import iloading from "@/assets/icons/circle-04.vue";

export default ({
    props: ['loadingValue'],
    components: {
        // iloading
    }
    // watch: {
    //   $props: {
    //   handler() {
    //     this.parseData();
    //   },
    //   deep: true,
    //   immediate: true,
    //   }
    // }
})
</script>
<style>
.isocol:before {
  background-image: url(../assets/Fascia_6.png);
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: 100%;
  bottom: 0;
  display: block;
  left: 0;
  overflow: hidden;
  padding-top: 10vh;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
}
.loader {
  position: relative;
  width: 100vw;
  height: 100vh;
}
#loadingvue {
  z-index: 99;
}
@media screen and (orientation: portrait) {
  .isocol {
    padding-bottom: 20%;

  }
  h1 {
    font-size:2rem;
  }
  
}
.progress {
  background-color: var(--gray-dark) ;
}
.progress-bar {
  background-color: white;
}
</style>