<template>
  <transition appear name="slide-left">
    <div class="contents" v-if="conf">
      <div class="card">
        <div class="card-header">
          <transition name="typewriter">
            <h1 class="card-title">{{ conf && conf.title }}</h1>
          </transition>
        </div>
        <div class="raimbow"></div>
        <div class="card-body">
          <b-button
            variant="outline-primary"
            v-for="(video,i) in conf.videoLink"
            :key="i"
            @click="showVideo(video.name,video.vlink)"
            appear
          >
            {{video.name}}
            <vpicon></vpicon>
          </b-button>
          <b-button
            v-if="conf && conf.immersive && conf.immersive.spheres"
            variant="outline-primary"
            @click="$router.push({path: '/immersive/' + $props.contentKey, params:{Pid:1}})"
          > {{ conf && conf.immersiveTitle }} 360
            <!-- {{getEnterText(conf && conf.title)}}{{ conf && conf.title }} -->
            <content360icon></content360icon>
          </b-button>
          <b-modal
            id="modalVideo"
            :title="modal.title"
            size="xl"
            class="bg-dark raimbow"
            hide-footer
          >
            <div class="raimbow"></div>
            <div class="embed-responsive embed-responsive-16by9">
              <iframe v-bind:src="'//player.vimeo.com/video/' + modal.videoId"></iframe>
            </div>
            {{modal.text}}
          </b-modal>
          <p></p>
          <p class="card-text">{{ conf && conf.caption }}</p>
          <!--Elenco aggiunto da Chiara-->
          <div v-if="conf.hasOwnProperty('Applicazioni')">
            <h3>{{getText('APPLICATIONS')}}</h3>
            <div v-for="(app,key) in conf.Applicazioni" :key="key">
              <b-link
                :v-b-modal="key"
                @click="showVideo(app.title,app.videoLink,app.caption)"
              >{{app.title}}</b-link>
            </div>
          </div>
          <br />
        </div>
        <!-- <footernav></footernav> -->
      </div>
    </div>
  </transition>
</template>
<script>
// import footernav from "@/components/footernav.vue";
import json from "@/json/data.json";
import content360icon from "@/assets/icons/content-360deg-2.vue";
import vpicon from "@/assets/icons/video-player.vue";
import { ConfigurationManager } from "@/assets/js/configuration-manager.js";

export default {
  name: "Contentcard",
  props: ["contentKey"],
  components: {
    // footernav,
    content360icon,
    vpicon
  },
  watch: {
    contentKey: function(n, o) {
      ConfigurationManager.load(conf => {
        this.conf = conf[n];
        console.log(this.conf);
      });
    }
  },
  data() {
    return {
      conf: null,
      modal: {
        title: "",
        videoId: "",
        text: ""
      }
    };
  },
  methods: {
    showVideo(title, videoId, text) {
      this.modal.title = title;
      this.modal.videoId = videoId;
      this.$bvModal.show("modalVideo");
      if (text) {
        this.modal.text = text;
      } else {
        this.modal.text = "";
      }
    },
    getText: function(key) {
      return ConfigurationManager.getText(key);
    },
    getEnterText(title) {
      if (title) {
        if (["a", "e", "i", "o", "u"].includes(title[0].toLowerCase())) {
          return this.getText("ENTER_1");
        } else if (title.substring(0, 4).toLowerCase() == "sala") {
          return this.getText("ENTER_3");
        } else {
          return this.getText("ENTER_2");
        }
      } else return "";
    }
  },
  mounted() {
    ConfigurationManager.onLanguageChanged(() => {
      ConfigurationManager.load(conf => {
        this.conf = conf[this.contentKey];
      });
    });
    if (this.contentKey) {
      ConfigurationManager.load(conf => {
        this.conf = conf[this.contentKey];
      });
    }
  }
};
</script>
<style>
.modal-content {
  background-color: black;
  color: white;
}
.modal-header .close {
  color: white;
}
.modal-header::after {
  height: 3px;
  background: linear-gradient(
    90deg,
    rgba(226, 6, 19, 1) 0%,
    rgba(255, 236, 0, 1) 16%,
    rgba(0, 149, 64, 1) 43%,
    rgba(49, 39, 130, 1) 72%,
    rgba(225, 0, 126, 1) 100%
  ) !important;
}
h1.card-title {
  font-size:1.8rem
}
</style>