<template>
  <b-navbar class="header" type="dark" variant="dark" fixed="top">
    <b-link class="nav-item nav-link active" @click="$router.push('/')">
    <b-img
      :src="require('../assets/Logo_Elettra_Social_bianco_v2.png')"
      alt="Logo Elettra"
      style="height: 30px;"
    ></b-img>
    </b-link>
    <b-navbar-brand>
      <b-link class="nav-item nav-link active" @click="$router.push('/')">
        Elettra Sincrotrone Trieste Virtual Visit
        <span class="sr-only">(current)</span>
      </b-link>
    </b-navbar-brand>
    <b-navbar-nav class="ml-auto">
      <b-dropdown id="dropdown-left" text="Lang" variant="outline-secondary" class="m-2">
        <b-dropdown-item @click="changeLanguage('IT')" href="#">ITA</b-dropdown-item>
        <b-dropdown-item @click="changeLanguage('ENG')" href="#">ENG</b-dropdown-item>
      </b-dropdown>
      <b-nav-item right>
        <!-- <b-button outline variant="outline-secondary" @click="myToggle = !myToggle"><vactive v-if="myToggle"></vactive><vmute v-if="!myToggle"></vmute></b-button> -->
        <b-button outline variant="outline-secondary" v-b-toggle.my-sidebar>
          <menuicon></menuicon>
        </b-button>
      </b-nav-item>
    </b-navbar-nav>
  </b-navbar>
</template>
<script>
import menuicon from "@/assets/icons/menu-8.vue";
import { ConfigurationManager } from "@/assets/js/configuration-manager.js";
// import vmute from '@/assets/icons/volume-mute.vue'
// import vactive from '@/assets/icons/volume-2.vue'
export default {
  name: "Navbar",
  components: {
    menuicon
    // vmute,
    // vactive
  },
  methods: {
    changeLanguage: function(lang) {
      ConfigurationManager.setLanguage(lang);
    }
  },

  data() {
    return {
      myToggle: true
    };
  }
  /*
    //Funzione per toggle audio
    methods: {
    toggleAudio: function() {
    }
    */
};
</script>
<style lang="css">
nav.bg-dark {
  background-color: black !important;
  z-index: 1!important;
}
.nav-item {
  color: rgba(255, 255, 255, 0.9);
}
.nav-item:hover {
  color: white;
}
.btn-secondary {
  background-color: black;
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: white !important;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: black !important;
  border-color: white !important;
}
</style>