<template>
<panorama></panorama>
</template>
<script>
import Panorama from '../components/panorama.vue'
export default {
    name: 'Immersive',
    components: {
        Panorama
    }
}
</script>
<style>

</style>