import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { ConfigurationManager } from "@/assets/js/configuration-manager.js";

// import { VRButton } from 'three/examples/jsm/webxr/VRButton.js';
// import { XRControllerModelFactory } from 'three/examples/jsm/webxr/XRControllerModelFactory.js';

// import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
// import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader.js';

const Pablorama = (function () {

    let configuration;

    let options = {
        container: document.getElementById("container")
    }

    let camera, scene, renderer, controls, skyBox;

    let hotspots = new THREE.Group();
    let htmlHotspots = [];
    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();
    const sphere = new THREE.Group();

    function onMouseMove(event) {

        // calculate mouse position in normalized device coordinates
        // (-1 to +1) for both components

        mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        mouse.y = - (event.clientY / window.innerHeight) * 2 + 1;

    }

    function log(what, level) {
        console.log(what);
    }

    function fetchJson(url) {
        return new Promise(function (resolve, reject) {
            fetch(url).then(function (response) {
                response.json().then(resolve)
            }).catch(function (err) {
                log(err);
            });
        })

    }
    function loadGLTF(url, callback) {
        const manager = new THREE.LoadingManager();


        manager.onProgress = function (url, itemsLoaded, itemsTotal) {

            log('Loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.', true)

        };
        const loader = new GLTFLoader(manager);


        // load a resource
        fetchWithProgress(url, function (percent) {
            log(percent + '%', true);
        }, function (data) {
            loader.parse(data, null, function (gltf) {

                log("material")
                callback && callback(gltf.scene, gltf);
            });
        }, true);
    }


    function fetchWithProgress(url, progress, callback, binary) {
        var request = new XMLHttpRequest();

        if (binary) {

            request.responseType = "arraybuffer";
        }

        request.addEventListener('progress', function (e) {
            var percent_complete = (e.loaded / e.total) * 100;
            progress(percent_complete);
        });
        request.addEventListener('load', function (e) {
            callback(request.response);
        });

        request.open('get', url);

        request.send();
    }

    function init(conf, opts) {
        opts = opts || {};

        for (let key in opts) {
            options[key] = opts[key];
        }



        return new Promise(function (resolve, reject) {
            log("Init with configuration:");
            configuration = conf;
            log(configuration);

            initScene();
            let first = Object.keys(configuration)[0];
            if (options.startingSphere) first = options.startingSphere;
            loadSphereByKey(first);
        });
    }

    function initScene() {
        scene = new THREE.Scene();
        //scene.background = new THREE.Color(0x808080);

        camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 100);

        camera.position.z = 0.01;
        camera.position.x = 0;




        // scene.add(new THREE.HemisphereLight(0xffffff, 0xffffff, 1));

        renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(window.innerWidth, window.innerHeight);
        renderer.outputEncoding = THREE.sRGBEncoding;
        renderer.shadowMap.enabled = true;
        renderer.xr.enabled = true;
        options.container.appendChild(renderer.domElement);

        controls = new OrbitControls(camera, renderer.domElement);
        controls.enableZoom = false;
        controls.enablePan = false;
        //controls.enableDamping = true;
        controls.rotateSpeed = - 1;


        window.addEventListener('resize', onWindowResize);
        renderer.domElement.addEventListener('mouseup', onMouseUp, false);
        renderer.domElement.addEventListener('mousedown', onMouseDown, false);
        document.body.addEventListener('keyup', handleKeyUp, false);
        document.body.addEventListener('keydown', handleKeyDown, false);


        renderer.setAnimationLoop(render);

        scene.add(hotspots);
        scene.add(sphere);

        //skybox

        /*
        // Create the panoramic sphere geometery
        const panoSphereGeo = new THREE.SphereGeometry(6, 256, 256);

        // Create the panoramic sphere material
        const panoSphereMat = new THREE.MeshStandardMaterial({
            side: THREE.BackSide,
            displacementScale: - 4.0,
            color: 0xffff00
        });

        // Create the panoramic sphere mesh
        skyBox = new THREE.Mesh(panoSphereGeo, panoSphereMat);
        scene.add(skyBox);

        */
        /*
         loadGLTF('scene.gltf', function (obj, full) {
             obj.position.x = 2;
             scene.add(obj);
         })*/
    }
    var mouseDownPosition = [];
    function onMouseDown(event) {

        mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        mouse.y = - (event.clientY / window.innerHeight) * 2 + 1;
        mouseDownPosition = [mouse.x, mouse.y];
    }

    function onMouseUp(event) {

        mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        mouse.y = - (event.clientY / window.innerHeight) * 2 + 1;

        if (mouse.x != mouseDownPosition[0] || mouse.y != mouseDownPosition[1]) {
            console.log("It was a drag");
            return;
        }

        raycaster.setFromCamera(mouse, camera);
        const intersects = raycaster.intersectObjects(hotspots.children, true);
        if (intersects[0]) {
            console.log(intersects)
            handleHotspotClick(intersects[0].object);
        }
    }

    const keyPressed = {
        UP: false,
        DOWN: false,
        LEFT: false,
        RIGHT: false,
    }

    function handleKeyDown(e) {
        switch (e.which) {
            case 38:
                keyPressed.UP = true;
                break;
            case 40:
                keyPressed.DOWN = true;
                break;
            case 39:
                keyPressed.LEFT = true;
                break;
            case 37:
                keyPressed.RIGHT = true;
                break;
        }
    }
    function handleKeyUp(e) {
        switch (e.which) {
            case 38:
                keyPressed.UP = false;
                break;
            case 40:
                keyPressed.DOWN = false;
                break;
            case 39:
                keyPressed.LEFT = false;
                break;
            case 37:
                keyPressed.RIGHT = false;
                break;
        }
    }

    function onWindowResize() {
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();

        renderer.setSize(window.innerWidth, window.innerHeight);
    }

    function getCoordsFromString(str) {
        return new THREE.Vector3(...str.split(',').map(e => e * 1));
    }

    function updateHTMLHotspots() {
        htmlHotspots.forEach(e => {
            updateHTMLHotspotPosition(e);
        });
    }

    function updateHTMLHotspotPosition(e) {
        const d = e.element;
        let { top, left } = vector2TopLeft(e.position);
        d.style.top = top + 'px';
        d.style.left = left + 'px';

        //check if behind camera
        try {
            var target = new THREE.Vector3().copy(e.position);
            var lookAt = new THREE.Vector3(0, 0, - 1);
            lookAt.applyQuaternion(camera.quaternion);
            var cameraPos = new THREE.Vector3().setFromMatrixPosition(camera.matrixWorld);

            var pos = target.sub(cameraPos);

            let behind = (pos.angleTo(lookAt)) > (Math.PI / 2);
            if (behind) {
                d.style.visibility = 'hidden';
            } else {
                d.style.visibility = 'visible';
            }
        } catch (e) {
            console.log(e)
        }

    }
    function vector2TopLeft(v) {

        const vect = new THREE.Vector3(v.x, v.y, v.z);
        camera.updateMatrixWorld();
        vect.project(camera);
        const left = (vect.x + 1) * options.container.clientWidth / 2;
        const top = -(vect.y - 1) * options.container.clientHeight / 2;

        return { left, top };
    }
    let hotspotTemplate = null;
    function getHotspotTemplate(cb) {
        if (hotspotTemplate) {
            cb && cb(hotspotTemplate);
            return;
        }

        fetch('templates/hotspot.html').then(data => {
            data.text().then(template => {
                var div = document.createElement('div');
                div.innerHTML = template.trim();

                hotspotTemplate = div.firstChild;
                cb && cb(div.firstChild);
            })
        });
    }

    var interpolate = function (text, dictionary) {
        var split1 = text.split('{{');

        var vps = [];


        for (let i = 1; i < split1.length; i++) {
            var split2 = split1[i].split("}}");

            vps.push(split2[0]);
        }

        for (let i = 0; i < vps.length; i++) {
            text = text.replace("{{" + vps[i] + "}}", dictionary[vps[i]] || "[NO_CONTENT]");
        }

        return text;
    }

    function createHTMLHotspot(template, hotspotConf) {

        let d = template.cloneNode(true)
        d.innerHTML = interpolate(d.innerHTML, hotspotConf);
        for (let i = 0; i < d.attributes.length; i++) {
            d.attributes[i].nodeValue = interpolate(d.attributes[i].nodeValue, hotspotConf);
        }

        const position = getCoordsFromString(hotspotConf.coordPos);
        let { top, left } = vector2TopLeft(position);
        //d.className += ' ' + hotspotConf.class;
        d.style.position = 'absolute';
        d.style.top = top + 'px';
        d.style.left = left + 'px';
        if (hotspotConf.url) {
            d.onclick = function () {
                location.href = hotspotConf.url;
            }
        }

        getHotspotTemplate();

        htmlHotspots.push({ element: d, position: position });
        return d;
    }

    function loadHotspot(hotspotConf) {
        if (hotspotConf.objectUrl) {
            loadGLTF(hotspotConf.objectUrl, function (obj, full) {
                const position = getCoordsFromString(hotspotConf.coordPos);
                const rotation = getCoordsFromString(hotspotConf.coordRot);

                obj.position.copy(position);
                obj.rotation.x = rotation.x;
                obj.rotation.y = rotation.y;
                obj.rotation.z = rotation.z;
                obj.userData.conf = hotspotConf;
                obj.userData.type = 'hotspot';

                //obj.material.receiveShadow = true;

                hotspots.add(obj);
            });
        } else if (hotspotConf.type == 'link') {
            options.container.appendChild(createHTMLHotspot(hotspotTemplate, hotspotConf));
        } else {
            console.log("what")
        }

    }

    function findParentHotspot(obj) {
        if (obj.userData.type != 'hotspot') {
            return findParentHotspot(obj.parent);
        } else {
            return obj;
        }
    }

    function handleHotspotClick(obj) {
        obj = findParentHotspot(obj);
        if (obj.userData.conf.destinationSphere) {
            loadSphereByKey(obj.userData.conf.destinationSphere);
        }
    }

    function loadSphereByKey(key) {
        let d = location.hash.split('/')
        if (d.length > 3)
            d.pop()
        let s = d.join('/') + '/' + key;
        location.hash = s
        loadSphere(configuration[key]);
    }


    function loadSphere(sphereConf) {
        htmlHotspots.forEach(e => {
            e.element.parentElement.removeChild(e.element);
        })
        htmlHotspots = [];

        hotspots.remove(...hotspots.children)
        sphere.remove(...sphere.children)
        controls.enabled = false;

        if (sphereConf.initialLookAt) {
            const coord = getCoordsFromString(sphereConf.initialLookAt)
            camera.lookAt(coord);
        }

        /*let cube = new THREE.Mesh(new THREE.BoxGeometry(0.1, 0.1, 0.1), new THREE.MeshStandardMaterial({ color: 0xff0000 }))
        cube.position.set(0, 1, 2)
        sphere.add(cube)*/

        new THREE.TextureLoader().load(sphereConf.imglink, (texture) => {

            controls.enabled = true;


            texture.minFilter = THREE.LinearFilter;
            texture.generateMipmaps = false;
            texture.wrapS = THREE.RepeatWrapping;
            texture.repeat.x = -1;
            texture.encoding = THREE.sRGBEncoding;

            const skyBox = new THREE.Mesh(new THREE.SphereGeometry(30, 256, 256), new THREE.MeshStandardMaterial({
                map: texture,
                emissive: 0xffffff,
                emissiveMap: texture,
                side: THREE.BackSide,
                displacementScale: 1,
            }));

            sphere.add(skyBox);


            getHotspotTemplate(() => {
                for (var id in sphereConf.hotspots) {
                    let hotspotConf = sphereConf.hotspots[id];
                    loadHotspot(hotspotConf);
                }
            })
        });

    }
    let lastTime = 0;
    const KEYCONTROL_SENSIBILITY = 0.3;

    const vass = (v) => v > 0 ? v : -v;

    const lookAtPolar = { tetha: 0, phi: 0 }

    function render() {
        const time = performance.now() * 0.001;
        const delta = time - lastTime;
        lastTime = time;
        renderer.render(scene, camera);

        const s = 1 + Math.sin(time * 4) * 0.1;
        hotspots.traverse((e) => {
            if (e.children.length == 0) {
                e.scale.set(s, s, s);
            }
        });

        updateHTMLHotspots();

        /*
        if (keyPressed.UP) {
            lookAtPolar.phi += Math.PI * KEYCONTROL_SENSIBILITY * delta
        }
        if (keyPressed.DOWN) {
            lookAtPolar.phi -= Math.PI * KEYCONTROL_SENSIBILITY * delta
        }

        if (keyPressed.RIGHT) {
            lookAtPolar.tetha += Math.PI * KEYCONTROL_SENSIBILITY * delta;
        }
        if (keyPressed.LEFT) {
            lookAtPolar.tetha -= Math.PI * KEYCONTROL_SENSIBILITY * delta;
        }

        const x = Math.sin(lookAtPolar.tetha) * Math.cos(lookAtPolar.phi)
        const y = Math.sin(lookAtPolar.tetha) * Math.sin(lookAtPolar.phi)
        const z = Math.cos(lookAtPolar.tetha);

        camera.lookAt(x, y, z);*/
        //controls.update()
    }


    return {
        init: init,
        fetchJson: fetchJson,
        loadSphereByKey: loadSphereByKey
    };
})();

export { Pablorama }