<template>
  <div>
    <transition name="fade">
      <loadingscreen v-show="showLoading || !contentLoaded" :loadingValue="loadingProgress" />
      <!-- <div class="video-splash" v-show="showLoading || !contentLoaded">
        <div id="video-splash-loading">
          <div class="d-flex align-items-center justify-content-center loader" v-show="!showPlayer">
            <b-img
              style="width: 20%; margin-top:-10vh;"
              :src="require('../assets/Logo_Elettra_Social_bianco_v2.png')"
              alt="Logo Elettra"
            ></b-img>
            <h1 style="margin-top:-10vh;">
              Elettra Sincrotrone Trieste Virtual Visit
              <br />
              <b-progress :value="loadingProgress" :max="1" class="mb-3" height="2px"></b-progress>
            </h1>
            <div class="raimbow"></div>
            <iloading class="loading-spinner fixed" />
          </div>
        </div>
        <vimeo-player
          v-if="videoId"
          v-show="showPlayer"
          ref="splashPlayer"
          :autoplay="true"
          :controls="false"
          :player-height="splashHeight"
          :player-width="splashWidth"
          @ended="splashEnded"
          @ready="splashReady"
          :video-id="videoId"
        ></vimeo-player>
      </div>-->
    </transition>
    <contentcard-vue :contentKey="contentKey"></contentcard-vue>
    <transition name="fade">
      <edspace ref="edspace" @objectClick="handleClick" @objectHover="handleHover" />
    </transition>
  </div>
</template>
<script>
import ContentcardVue from "@/components/Contentcard.vue";
import Edspace from "../components/edspace.vue";
import { ConfigurationManager } from "@/assets/js/configuration-manager.js";
import * as THREE from "three";
//import vueVimeoPlayer from "vue-vimeo-player";
// import iloading from "@/assets/icons/circle-04.vue";
import Loadingscreen from "@/components/loading.vue";
const materialMap = {};
export default {
  components: {
    Edspace,
    ContentcardVue,
    Loadingscreen
  },
  watch: {
    $route(to, from) {
      this.getKeyByPath(to.path, key => {
        if (key) {
          this.loadContent(key);
        }
      });
    }
  },
  methods: {
    searchParentGroup(obj) {
      if (obj.userData.type == "gltf") return obj;
      if (!obj.parent) return null;
      return this.searchParentGroup(obj.parent);
    },
    handleHover(obj, scene) {
      if (this.lastHover == obj) return;
      this.lastHover = obj;

      scene.traverse(e => {
        if (e.isMesh && materialMap[e.id]) {
          // e.material.color = new THREE.Color(0xffffff);
          e.material = materialMap[e.id];
          delete materialMap[e.id];
        }

        /*if (e.isMesh && e.userData.type == "guscio") {
          //debug
          e.material.opacity = 0.5;
          e.material.color = new THREE.Color(0xffff00);
          e.material.transparent = true;
        }*/
      });

      if (obj) {
        obj = this.searchParentGroup(obj);
        obj.traverse(e => {
          if (e.isMesh && e.userData.type != "guscio") {
            // e.material.color = new THREE.Color(0xff0000);
            materialMap[e.id] = e.material;

            e.material = new THREE.MeshStandardMaterial({
              color: new THREE.Color(0xff0000)
            });
          }
        });
      }
    },
    handleClick(obj, scene) {
      scene.traverse(e => {
        if (e.isMesh && materialMap[e.id]) {
          // e.material.color = new THREE.Color(0xffffff);
          e.material = materialMap[e.id];
          delete materialMap[e.id];
        }
      });
      this.getPathByObjectName(obj.name, path => {
        if (path != this.$route.path) {
          this.$router.push(path);
        }
      });
    },
    loadContent(key, skipVideo) {
      this.contentLoaded = false;
      this.$refs.edspace.preloadEverything(progress => {
        if (progress == 1) {
          this.$refs.edspace.loadContent(key, () => {
            this.contentLoaded = true;
          });
        } else {
          this.loadingProgress = progress; //(progress * 100).toFixed(2) + "%";
        }
      });

      this.$refs.edspace.onTransitionEnded = function() {
        console.log("TRANSITION ENDED");
      };

      this.contentKey = key;

      this.showLoading = false;
      /*
      ConfigurationManager.load(conf => {
        const videoId = conf[key].videoTransition || conf[key].videoTransition0;

        this.showLoading = true;
        this.showPlayer = true;
        if (!skipVideo && videoId) {
          this.videoId = videoId;
          this.$refs.splashPlayer &&
            this.$refs.splashPlayer.play().catch(e => {
              console.log(e);
              setTimeout(() => {
                this.showLoading = false;
              }, 2000);
            });
        } else {
          //this.$refs.splashPlayer.pause();
          this.videoId = null;
          this.showPlayer = false;
          setTimeout(() => {
            console.log("stopping!");
            this.showLoading = false;
          }, 2000);
        }
      });*/
    },
    getKeyByPath(path, cb) {
      ConfigurationManager.load(conf => {
        for (let key in conf) {
          if (conf[key].path == path) {
            cb && cb(key);
            return;
          }
        }
      });
    },
    getPathByObjectName(name, cb) {
      ConfigurationManager.load(conf => {
        for (let key in conf) {
          if (conf[key].triggerObjectName == name) {
            cb && cb(conf[key].path);
            return;
          }
        }
      });
    },
    splashEnded() {
      this.showLoading = false;
    },
    splashReady() {
      this.showPlayer = true;
    },
    skipVideo() {
      this.$refs.splashPlayer.pause();
      this.showPlayer = false;
      this.showLoading = false;
    }
  },
  created() {
    ConfigurationManager.load(conf => {
      this.getKeyByPath(this.$route.path, key => {
        this.contentKey = key;
      });
    });
  },
  mounted() {
    this.showPlayer = false;
    ConfigurationManager.load(conf => {
      this.getKeyByPath(this.$route.path, key => {
        this.$refs.edspace.init();
        this.loadContent(key, true);

        this.$refs.edspace.onResize = function(isPortrait) {
          console.log("Is portait = " + isPortrait);
        };
      });
    });

    this.languageHandlerId = ConfigurationManager.onLanguageChanged(() => {
      this.getKeyByPath(this.$route.path, key => {
        this.loadContent(key);
      });
    });
  },
  destroyed() {
    ConfigurationManager.removeLanguageChangedHandler(this.languageHandlerId);
  },
  data() {
    return {
      contentKey: "",
      showLoading: true,
      showPlayer: true,
      videoId: null,
      splashHeight: window.innerHeight,
      splashWidth: window.innerWidth,
      contentLoaded: false,
      loadingProgress: 0
    };
  }
};
</script>
<style>
.loader {
  background-color: black;
  bottom: 0;
  color: white;
  display: block;
  left: 0;
  overflow: hidden;
  padding-top: 10vh;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99;
}

/* .loading-spinner.fixed {
  position: fixed;
  margin-top: 12vh;
} */
</style>