<template>
  <div :class="{ loader: true, fadeout: !isLoading }" id="loadingscreenvue">
  <!-- <div class="d-flex loader"> -->
    <b-container id="loadingscreenContainer" class="bv-example-row bv-example-row-flex-cols">
      <b-row class="vh-100" align-v="center">
        <b-col md="2" offset-md="2" sm="6">
          <b-img class="logoload" :src="require('../assets/Logo_Elettra_Social_bianco_v2.png')" fluid alt="Logo Elettra"></b-img>
        </b-col>
        <b-col md="8" sm="12">
          <h1>Elettra Sincrotrone Trieste Virtual Visit</h1>
          <b-progress :value="loadingValue" :max="1" class="mb-3" height="2px"></b-progress>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>

export default {
  name: "LoadingScreen",
  props: ["isLoading","loadingValue"],
  methods: {
    handleOrientationChange() {
      const orientation = window.screen.orientation.type
      if (orientation === "portrait-primary") {
        return true;
      } else if (orientation === "landscape-primary") {
        return false;
      }
    }
  }
}
</script>

<style>
.loader {
  background-color: black;
  background-image: url(../assets/Fascia_6.png);
  background-position: top left;
  background-repeat: no-repeat;
  background-size: 100%;
  bottom: 0;
  color: white;
  display: block;
  left: 0;
  overflow: hidden;
  padding-top: 10vh;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99;
}

.fadeout {
  animation-delay: 2s;
  animation: fadeout 2s forwards;
  z-index: 99;
}
#loadingscreenContainer {
  height: 100%
}

@keyframes fadeout {
  to {
    opacity: 0;
    visibility: hidden;
  }
}
.loadingbg {

}
</style>