<template><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" height="32" width="32"><title>{{title}}</title><g :fill="_secondaryfill"><path d="M63,32c0-5.9-5.476-11.09-13.789-14.139.21.784.408,1.579.583,2.4C56.565,23.032,61,27.3,61,32c0,5.2-5.437,9.881-13.465,12.582A53.355,53.355,0,0,0,49,32C49,14.879,41.389,1,32,1,26.1,1,20.91,6.476,17.861,14.789c.784-.21,1.579-.408,2.4-.583C23.032,7.435,27.3,3,32,3c5.2,0,9.881,5.437,12.582,13.465A53.355,53.355,0,0,0,32,15C14.879,15,1,22.611,1,32c0,5.9,5.476,11.09,13.789,14.139-.21-.784-.408-1.579-.583-2.4C7.435,40.968,3,36.7,3,32c0-5.2,5.437-9.881,13.465-12.582A53.355,53.355,0,0,0,15,32c0,17.121,7.611,31,17,31,5.9,0,11.09-5.476,14.139-13.789-.784.21-1.579.408-2.4.583C40.968,56.565,36.7,61,32,61c-5.2,0-9.881-5.437-12.582-13.465A53.355,53.355,0,0,0,32,49C49.121,49,63,41.389,63,32ZM17,32a50.524,50.524,0,0,1,1.729-13.272A50.553,50.553,0,0,1,32,17a50.524,50.524,0,0,1,13.272,1.729A50.553,50.553,0,0,1,47,32a50.524,50.524,0,0,1-1.729,13.272A50.553,50.553,0,0,1,32,47a50.524,50.524,0,0,1-13.272-1.729A50.553,50.553,0,0,1,17,32Z" :fill="fill"></path><circle cx="32" cy="32" r="8"></circle></g></svg></template>

<script>
export default {
	props: {
		title: {
			type: String,
			default: "content 360deg 2"
		},
		fill: {
			type: String,
			default: "currentColor"
		},
		secondaryfill: {
			type: String
		}
	},
	computed: {
		_secondaryfill: function () {
			return this.secondaryfill || this.fill
		}
	}
}
</script>