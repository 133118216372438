<template>
      <contentcard-vue></contentcard-vue>
</template>
<script>
import ContentcardVue from '@/components/Contentcard.vue'


export default {
  components: {
    ContentcardVue,

  }
}
</script>
<style>

</style>