<template>
<div class="card card-tut">
            <div class="card-header">
                <h1>How to use the tour</h1>
            </div>
            <div class="card-body" :key="index" v-for="(item, index) in paginatedItems">
                <h1>{{item.title}}</h1>
                <p>{{item.body}}</p>
            </div>
            <div class="card-footer">
                <b-pagination
                @change="onPageChanged"
                :total-rows="totalRows"
                :per-page="perPage"
                v-model="currentPage"
                class="my-0"
                >
                </b-pagination>
            </div>
</div>    

</template>

<script>
const items = [
  {
    title: "Navigate the spaces",
    body: "Drag around to move and zoom the spaces.",
    imgurl: "http://primary"
  },
  {
    title: "Secondary",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    url: "secondary"
  },
  {
    title: "Success",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    url: "success"
  },
  {
    title: "Info",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    url: "info"
  },
  {
    title: "Warning",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    url: "warning"
  },
  {
    title: "Danger",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    url: "danger"
  }
];

export default {
  name: "MyBootstrapGrid",
  data() {
    return {
      items: items,
      paginatedItems: items,
      currentPage: 1,
      perPage: 1,
      totalRows: items.length
    };
  },
  computed: {},
  methods: {
    paginate(page_size, page_number) {
      let itemsToParse = this.items;
      this.paginatedItems = itemsToParse.slice(
        page_number * page_size,
        (page_number + 1) * page_size
      );
    },
    onPageChanged(page) {
      this.paginate(this.perPage, page - 1);
    }
  },
  mounted() {
    this.paginate(this.perPage, 0);
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.coso {
    padding: 10%;
}
</style>