<template>
  <div>
    <b-button class="backbutton" size="lg" variant="outline-secondary" @click="goBack()">
      <navleft />
    </b-button>
    <div class="pablorama-loading">
      <iloading class="loading-spinner" />
    </div>
    <div id="container"></div>
  </div>
</template>
<script>
import json from "@/json/data.json";
import { Pablorama } from "@/assets/js/pablorama.js";
import { ConfigurationManager } from "@/assets/js/configuration-manager.js";
import navleft from "@/assets/icons/nav-left.vue";
import iloading from "@/assets/icons/circle-04.vue";

export default {
  name: "Panorama",
  components: {
    navleft,
    iloading
  },
  watch: {
    $route(to, from) {
      if (this.$route.params.key != this.key) {
        location.reload(); //todo pablorama.reset()
      }
      if (
        this.$route.params.sphere &&
        this.$route.params.sphere != this.sphere
      ) {
        this.sphere = this.$route.params.sphere;
        Pablorama.loadSphereByKey(this.sphere);
      }
    }
  },
  data() {
    return { myJson: json };
  },
  methods: {
    goBack() {
      location.hash = "#" + this.goBackHash;
    }
  },
  mounted() {
    ConfigurationManager.load(data => {
      const key = this.$route.params.id;
      const sphere = this.$route.params.sphere;
      this.key = key;
      this.goBackHash = data[key].path;
      this.sphere = sphere;
      console.log("SPHERE", sphere);
      Pablorama.init(data[key].immersive.spheres, {
        container: document.getElementById("container"),
        startingSphere: sphere || null
      }).then(function() {
        console.log("inited");
      });
    });
    this.languageHandlerId = ConfigurationManager.onLanguageChanged(() => {
       location.reload();
    });
  },
  destroyed() {
    ConfigurationManager.removeLanguageChangedHandler(this.languageHandlerId);
  },
};
</script>
<style>
#container {
  position: fixed;
  bottom: 0px;
}
</style>
