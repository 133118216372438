
const ConfigurationManager = (function () {
    let config = null;

    let currentLanguage = localStorage.currentLanguage || "IT";

    function load(cb) {
        if (!config) {
            fetch("/json/data.json").then(response => {
                response.json().then(data => {
                    config = data;
                    cb && cb(config[currentLanguage]);
                });
            });
        } else {
            cb && cb(config[currentLanguage]);
        }
    }

    function getConfiguration() {
        return config[currentLanguage];
    }

    function setLanguage(lang) {
        if (currentLanguage != lang) {
            currentLanguage = lang;
            languageChangedHandlers.forEach(e => e(lang));
            localStorage.currentLanguage = currentLanguage;
        }
    }

    let languageChangedHandlers = [];

    function onLanguageChanged(handler) {
        languageChangedHandlers.push(handler);
        return handler;
    }

    function removeLanguageChangedHandler(handler) {
        for (let i = 0; i < languageChangedHandlers.length; i++) {
            if (languageChangedHandlers[i] == handler) {
                languageChangedHandlers.splice(i, 1);
            }
        }
    }

    function getText(key) {
        if (config[currentLanguage]._content && config[currentLanguage]._content[key]) {
            return config[currentLanguage]._content[key];
        } else {
            return "[NO_CONTENT]";
        }
    }

    function getConfigurationByPath(path) {
        if (!config) return null;

        for (let key in config[currentLanguage]) {
            if (config[currentLanguage][key].path == path) {
                return config[currentLanguage][key];
            }
        }
    }

    return {
        load: load,
        getConfiguration: getConfiguration,
        setLanguage: setLanguage,
        getConfigurationByPath: getConfigurationByPath,
        onLanguageChanged: onLanguageChanged,
        removeLanguageChangedHandler: removeLanguageChangedHandler,
        getText: getText
    };
})();

export { ConfigurationManager }