<template><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" :width="width" :height="height"><title>{{title}}</title><g :fill="_secondaryfill"><g class="nc-loop_circle-04-64"> <path d="M32,64c-7.39551,0-14.60889-2.58203-20.31104-7.27148c-1.27979-1.05273-1.46436-2.94336-0.41211-4.22266 c1.05273-1.28027,2.94287-1.46387,4.22266-0.41211C20.13135,55.90234,25.99121,58,32,58c14.33643,0,26-11.66309,26-26 C58,17.66357,46.33643,6,32,6S6,17.66357,6,32c0,3.70605,0.7627,7.28418,2.26709,10.63574 c0.67871,1.51172,0.00342,3.28711-1.5083,3.96582c-1.51367,0.67676-3.28711,0.00293-3.96533-1.50879 C0.93994,40.96387,0,36.55859,0,32C0,14.35498,14.35498,0,32,0s32,14.35498,32,32C64,49.64453,49.64502,64,32,64z" transform="rotate(28.67350000143051 32 32)" :fill="fill"></path> </g> </g></svg></template>

<script>
export default {
	props: {
		width: {
			type: [Number, String],
			default: "100%"
		},
		height: {
			type: [Number, String],
			default: "100%"
		},
		strokewidth: {
			type: [Number, String],
			default: 1
		},
		title: {
			type: String,
			default: "circle 04"
		},
		fill: {
			type: String,
			default: "currentColor"
		},
		secondaryfill: {
			type: String
		}
	},
	computed: {
		_secondaryfill: function () {
			return this.secondaryfill || this.fill
		}
	}
}
</script>