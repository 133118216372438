<template>
  <div id="scene-container" ref="sceneContainer"></div>
</template>

<script>
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { ConfigurationManager } from "@/assets/js/configuration-manager.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

export default {
  name: "edspace",
  data() {
    return {
      container: null,
      scene: null,
      camera: null,
      controls: null,
      renderer: null,
      stats: null,
      groups: null,
      INTERSECTED: null,
      mixer: null,
      clock: new THREE.Clock(),
      cachedObjects: {},
      cameraDestination: {
        active: false,
        position: new THREE.Vector3(),
        lookAt: new THREE.Vector3(),
        startPosition: new THREE.Vector3(),
        startLookAt: new THREE.Vector3(),
        progress: 0
      },
      transparentMap: {},
      htmlHotspots: []
    };
  },
  props: [],
  methods: {
    init() {
      // set container
      this.container = this.$refs.sceneContainer;
      this.intersectables = [];

      // add camera
      const fov = 50; // Field of view
      const aspect = this.container.clientWidth / this.container.clientHeight;
      const near = 0.1; // the near clipping plane
      const far = 3000; // the far clipping plane
      const camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
      camera.position.set(0, 5, 10);
      this.camera = camera;
      // create scene
      this.scene = new THREE.Scene();

      {
        const color = 0xffffff; // white
        const near = 10;
        const far = 50;
        this.scene.fog = new THREE.Fog(color, near, far);
      }
      this.scene.background = new THREE.Color("white");
      // add lights
      const ambientLight = new THREE.HemisphereLight(
        0xffffff, // bright sky color
        0x222222, // dim ground color
        2.5 // intensity
      );
      const mainLight = new THREE.DirectionalLight(0xffffff, 1.0);
      mainLight.position.set(10, 10, 10);
      this.scene.add(ambientLight, mainLight);
      // add controls
      this.controls = new OrbitControls(this.camera, this.container);
      this.controls.autoRotateSpeed = -3.0;
      this.controls.enableDamping = true; // an animation loop is required when either damping or auto-rotation are enabled
      this.controls.dampingFactor = 0.05;
      this.controls.minPolarAngle = 0;
      this.controls.maxPolarAngle = Math.PI * 0.4;
      this.controls.minDistance = 15;
      this.controls.maxDistance = 30;
      this.controls.target.set(0, 0, -0.2);
      this.controls.enablePan = false;
      // add sprites
      /*this.group = new THREE.Group();
      this.scene.add(this.group);

      const sprite1 = new THREE.Sprite(
        new THREE.SpriteMaterial({ color: "#69f" })
      );
      sprite1.position.set(0, 2, 0);
      sprite1.scale.set(2, 2, 2);
      this.group.add(sprite1);

      const sprite2 = new THREE.Sprite(
        new THREE.SpriteMaterial({ color: "#09f" })
      );
      sprite2.position.set(8, 2, -6);
      sprite2.scale.set(2, 2, 2);
      this.group.add(sprite2);*/

      // Raycaster
      this.raycaster = new THREE.Raycaster();
      this.mouse = new THREE.Vector2();
      this.mouseInited = false;

      this.container.addEventListener("mousemove", e => {
        this.mouseInited = true;
        this.mouse.x = (event.clientX / this.container.clientWidth) * 2 - 1;
        this.mouse.y = -(event.clientY / this.container.clientHeight) * 2 + 1;
      });
      this.container.addEventListener("mousedown", e => {
        this.mouseDownPosition = [event.clientX, event.clientY];
      });
      this.container.addEventListener("mouseup", e => {
        if (
          event.clientX != this.mouseDownPosition[0] ||
          event.clientY != this.mouseDownPosition[1]
        ) {
          console.log("It was a drag");
          return;
        }

        const mouse = new THREE.Vector2();

        mouse.x = (event.clientX / this.container.clientWidth) * 2 - 1;
        mouse.y = -(event.clientY / this.container.clientHeight) * 2 + 1;

        const intersection = this.getIntersection(mouse);
        if (intersection && !this.conf.disableModelInteraction)
          this.$emit("objectClick", intersection, this.scene);
      });

      window.addEventListener("resize", () => {
        this.camera.aspect =
          this.container.clientWidth / this.container.clientHeight;
        this.camera.updateProjectionMatrix();

        this.renderer.setSize(
          this.container.clientWidth,
          this.container.clientHeight
        );

        if (this.onResize) {
          this.onResize &&
            this.onResize(window.innerHeight > window.innerWidth);
        }
      });

      // create renderer
      this.renderer = new THREE.WebGLRenderer({
        antialias: true //changed for render pass
      });
      this.renderer.setSize(
        this.container.clientWidth,
        this.container.clientHeight
      );
      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.gammaFactor = 2.2;
      this.renderer.outputEncoding = THREE.sRGBEncoding;
      this.renderer.physicallyCorrectLights = true;
      this.container.appendChild(this.renderer.domElement);
      // render pass test

      // set aspect ratio to match the new browser window aspect ratio
      this.camera.aspect =
        this.container.clientWidth / this.container.clientHeight;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(
        this.container.clientWidth,
        this.container.clientHeight
      );
      this.loader = new GLTFLoader();
      // Edges
      // const edges = new THREE.EdgesGeometry( loader );
      //const line = new THREE.LineSegments( edges, new THREE.LineBasicMaterial( { color: 'white' } ) );
      //this.scene.add( line );

      this.animate();

      /*this.renderer.setAnimationLoop(() => {
        this.render();
      });*/
    },
    initObjects() {},
    getCenterPoint(mesh) {
      var middle = new THREE.Vector3();
      var geometry = mesh.geometry;

      geometry.computeBoundingBox();

      middle.x = (geometry.boundingBox.max.x + geometry.boundingBox.min.x) / 2;
      middle.y = (geometry.boundingBox.max.y + geometry.boundingBox.min.y) / 2;
      middle.z = (geometry.boundingBox.max.z + geometry.boundingBox.min.z) / 2;

      mesh.localToWorld(middle);
      return middle;
    },

    getTextTexture(text, secondaryText) {
      let canvas = document.createElement("canvas");
      canvas.width = 3000;
      canvas.height = 3000;
      let ctx = canvas.getContext("2d");
      ctx.fillStyle = "#050505";
      //ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.font = "120px sans-serif";
      ctx.textAlign = "center";
      ctx.fillStyle = "#00476E";
      ctx.fillText(text, canvas.width / 2, canvas.height / 2);

      return new THREE.CanvasTexture(canvas);
    },
    getConfByGuscio(guscio) {
      const conf = ConfigurationManager.getConfiguration();
      for (let key in conf) {
        if (guscio.name == conf[key].triggerObjectName) {
          return conf[key];
        }
      }
      return null;
    },
    removeAllHTMLLabels() {
      this.htmlHotspots.forEach(e => {
        e.element.parentElement.removeChild(e.element);
      });
      this.htmlHotspots = [];
    },
    generateSprite(guscio) {
      const conf = this.getConfByGuscio(guscio);
      if (!conf) return;

      const sprite = new THREE.Sprite(
        new THREE.SpriteMaterial({
          map: this.getTextTexture(conf.title),
          transparent: true,
          depthWrite: false
        })
      );

      sprite.userData.type = "sprite";
      sprite.visible = false;
      sprite.userData.toAdd = true;

      sprite.position.copy(guscio.position);
      if (conf.sprite && conf.sprite.offset) {
        const offset = this.getCoordsFromString(conf.sprite.offset);
        console.log(offset);
        sprite.position.x += offset.x;
        sprite.position.y += offset.y;
        sprite.position.z += offset.z;
      } else {
        sprite.position.y += 0.5;
      }
      if (conf.sprite && conf.sprite.scale) {
        sprite.scale.set(conf.sprite.scale * 1, conf.sprite.scale * 1, 1);
      } else {
        sprite.scale.set(5, 5, 1);
      }

      const material = new THREE.LineBasicMaterial({
        color: 0x00476e
      });

      const points = [];
      points.push(new THREE.Vector3(0, -0.02, 0));
      points.push(new THREE.Vector3(0, -0.1, 0));

      const geometry = new THREE.BufferGeometry().setFromPoints(points);

      const line = new THREE.Line(geometry, material);
      line.userData.type = "sprite";

      sprite.add(line);

      const d = this.createHTMLLabel(sprite.position, conf);
      this.container.appendChild(d);

      //this.scene.add(sprite);
    },
    loadGLTFFromData(gltf) {
      gltf.scene.traverse(e => {
        if (e.name.split("-")[0] == "Guscio") {
          e.userData.type = "guscio";
          this.intersectables.push(e);

          if (!this.conf.disableModelInteraction) {
            this.generateSprite(e);
          }
        } else {
          e.userData.toAdd = true;
        }
      }, true);

      gltf.scene.userData.type = "gltf";
      gltf.scene.userData.toRemove = false;
      gltf.scene.userData.toAdd = true;
      //gltf.scene.visible = false;
      this.scene.add(gltf.scene);
      //gltf.scene.scale.set(1, 1, 1);

      if (gltf.animations.length) {
        console.log(
          "Found " +
            gltf.animations.length +
            " animations, playing them in loop"
        );
        this.mixer = new THREE.AnimationMixer(gltf.scene);
        gltf.animations.forEach(animation => {
          console.log(animation.name);
          let clip = this.mixer.clipAction(animation);
          clip.play();
        });
      }
    },
    preloadList(list, progressCallback, i) {
      if (!i) i = 0;
      if (i == list.length) {
        progressCallback(1);
        return;
      } else {
        progressCallback(i / list.length);
        if (this.cachedObjects[list[i]]) {
          this.preloadList(list, progressCallback, i + 1);
          return;
        }
        this.loader.load(
          list[i],
          gltf => {
            this.cachedObjects[list[i]] = gltf;
            this.preloadList(list, progressCallback, i + 1);
          },
          undefined,
          undefined
        );
      }
    },
    preloadEverything(progressCallback) {
      let list = [];

      let conf = ConfigurationManager.getConfiguration();
      for (let key in conf) {
        if (conf[key].geometries) {
          list = list.concat(conf[key].geometries);
        }
      }

      this.preloadList(list, progressCallback);
    },
    loadGLTF(path, oldConf, callback) {
      if (this.cachedObjects[path]) {
        this.loadGLTFFromData(this.cachedObjects[path]);
        callback && callback();
      } else {
        this.loader.load(
          path,
          gltf => {
            gltf.scene.userData.path = path;
            this.cachedObjects[path] = gltf;
            if (this.conf !== oldConf) return;
            this.loadGLTFFromData(gltf);
            callback && callback();
          },
          undefined,
          undefined
        );
      }
    },
    getCoordsFromString(str) {
      return new THREE.Vector3(...str.split(",").map(e => e * 1));
    },
    loadContent(what, callback) {
      this.conf = ConfigurationManager.getConfiguration()[what];

      if (this.conf.backgroundColor) {
        this.scene.background = new THREE.Color(this.conf.backgroundColor);
      } else {
        this.scene.background = new THREE.Color("#ffffff");
      }

      /*this.scene.children
        .filter(e => e.userData.type == "gltf" || e.userData.type == "sprite")
        .forEach(e => {
          e.userData.toFadeOut = 1;
          e.userData.toFadeIn = 0;
        });*/

      /*
this.scene.remove(
        ...this.scene.children.filter(
          e => e.userData.type == "gltf" || e.userData.type == "sprite"
        )
      );

    */
      if (this.conf.geometries) {
        //to check it's working the prevent reload same gltf
        const geometriesToLoad = this.conf.geometries.filter(
          e => this.scene.children.filter(c => c.userData.path == e).length == 0
        );

        this.scene.children
          .filter(
            e =>
              (e.userData.type == "gltf" || e.userData.type == "sprite") &&
              !geometriesToLoad.includes(e.userData.path)
          )
          .forEach(e => {
            if (e.userData.type !== "guscio") {
              e.userData.toRemove = true;
              e.traverse(e => {
                if (e.userData.type !== "guscio") {
                  e.userData.toRemove = true;
                }
              });
              if (e.userData.type == "sprite") {
                e.visible = false;
              }
            }
          });
        this.intersectables = [];
        this.removeAllHTMLLabels();
        let toLoad = geometriesToLoad.length;
        geometriesToLoad.forEach(g => {
          this.loadGLTF(g, this.conf, () => {
            toLoad--;
            if (toLoad == 0) {
              callback && callback();
            }
          });
        });
      }
      if (this.conf.camera && this.conf.camera.position) {
        this.cameraDestination.progress = 0;
        this.cameraDestination.active = true;
        this.controls.enabled = false;
        this.cameraDestination.position.copy(
          this.getCoordsFromString(this.conf.camera.position)
        );
      }
      if (this.conf.camera && this.conf.camera.lookAt) {
        /*this.controls.target.copy(
          this.getCoordsFromString(this.conf.camera.lookAt)
        );*/
        this.cameraDestination.progress = 0;
        this.cameraDestination.active = true;
        this.controls.enabled = false;
        this.cameraDestination.lookAt.copy(
          this.getCoordsFromString(this.conf.camera.lookAt)
        );
      }
      if (this.conf.camera && this.conf.camera.minDistance) {
        this.cameraDestination.minDistance = this.conf.camera.minDistance * 1;
      } else {
        this.cameraDestination.minDistance = null;
      }
      if (this.conf.camera && this.conf.camera.maxDistance) {
        this.cameraDestination.maxDistance = this.conf.camera.maxDistance * 1;
      } else {
        this.minDistance.maxDistance = null;
      }

      if (this.conf.camera && this.conf.camera.autoRotate) {
        this.controls.autoRotate = this.conf.camera.autoRotate;
      } else {
        this.controls.autoRotate = false;
      }
    },
    getIntersection(mouse) {
      if (!this.mouseInited) return [];
      this.raycaster.setFromCamera(mouse, this.camera);

      // calculate objects intersecting the picking ray
      const intersects = this.raycaster.intersectObjects(this.intersectables);

      return intersects[0] ? intersects[0].object : null;
      /*
      if (intersects.length) {
        const obj = intersects[0].object;

        const searchParentIntersectable = obj => {
          if (obj.userData.type == "gltf") return obj;
          if (!obj.parent) return null;
          return searchParentIntersectable(obj.parent);
        };
        const intersectable = searchParentIntersectable(obj);
        return intersectable;
      }

      return null;*/
    },
    animate() {
      requestAnimationFrame(this.animate);
      this.controls.update();
      this.render();
    },

    interpolate(text, dictionary) {
      var split1 = text.split("{{");

      var vps = [];

      for (let i = 1; i < split1.length; i++) {
        var split2 = split1[i].split("}}");

        vps.push(split2[0]);
      }

      for (let i = 0; i < vps.length; i++) {
        text = text.replace(
          "{{" + vps[i] + "}}",
          dictionary[vps[i]] || "[NO_CONTENT]"
        );
      }

      return text;
    },
    vector2TopLeft(v) {
      const vect = new THREE.Vector3(v.x, v.y, v.z);
      this.camera.updateMatrixWorld();
      vect.project(this.camera);
      const left = ((vect.x + 1) * this.renderer.domElement.clientWidth) / 2;
      const top = (-(vect.y - 1) * this.renderer.domElement.clientHeight) / 2;

      return { left, top };
    },
    createHTMLLabel(position, data) {
      let d = document.createElement("div");

      d.className = "hotspot-3d";
      d.innerHTML = "{{title}}";

      d.innerHTML = this.interpolate(d.innerHTML, data);
      for (let i = 0; i < d.attributes.length; i++) {
        d.attributes[i].nodeValue = this.interpolate(
          d.attributes[i].nodeValue,
          data
        );
      }

      let { top, left } = this.vector2TopLeft(position);
      d.style.position = "absolute";
      d.style.zIndex = 9999;
      d.style.top = top + "px";
      d.style.left = left + "px";

      this.htmlHotspots.push({ element: d, position: position });
      return d;
    },
    moveCameraToDestination(delta) {
      const TOTAL_DURATION = 3;

      if (this.cameraDestination.progress == 0) {
        this.cameraDestination.startPosition.copy(this.camera.position);
        this.cameraDestination.startLookAt.copy(this.controls.target);
        this.transparentMap = {};
        this.scene.traverse(e => {
          if (e.material) this.transparentMap[e.id] = e.material.transparent;
        }, true);
      }

      this.cameraDestination.progress += delta / TOTAL_DURATION;
      if (this.cameraDestination.progress > 1) {
        this.cameraDestination.progress = 1;
      }
      const progress = Math.sin(
        (this.cameraDestination.progress * Math.PI) / 2
      );

      this.camera.position.x =
        this.cameraDestination.startPosition.x +
        (this.cameraDestination.position.x -
          this.cameraDestination.startPosition.x) *
          progress;
      this.camera.position.y =
        this.cameraDestination.startPosition.y +
        (this.cameraDestination.position.y -
          this.cameraDestination.startPosition.y) *
          progress;
      this.camera.position.z =
        this.cameraDestination.startPosition.z +
        (this.cameraDestination.position.z -
          this.cameraDestination.startPosition.z) *
          progress;

      this.controls.target.x =
        this.cameraDestination.startLookAt.x +
        (this.cameraDestination.lookAt.x -
          this.cameraDestination.startLookAt.x) *
          progress;
      this.controls.target.y =
        this.cameraDestination.startLookAt.y +
        (this.cameraDestination.lookAt.y -
          this.cameraDestination.startLookAt.y) *
          progress;
      this.controls.target.z =
        this.cameraDestination.startLookAt.z +
        (this.cameraDestination.lookAt.z -
          this.cameraDestination.startLookAt.z) *
          progress;

      this.scene.traverse(e => {
        if (e.userData.toRemove && e.material) {
          e.material.transparent = true;
          e.material.opacity = 1 - this.cameraDestination.progress;
          if (e.material.opacity < 0) e.material.opacity = 0;
        }
        if (e.userData.toAdd && e.material) {
          e.material.transparent = true;
          e.material.opacity = this.cameraDestination.progress * 2;
          if (e.material.opacity > 1) e.material.opacity = 1;
        }
      }, true);

      this.camera.lookAt(this.controls.target);
      this.controls.update();

      if (this.cameraDestination.progress == 1) {
        this.controls.minDistance = this.cameraDestination.minDistance || null;
        this.controls.maxDistance = this.cameraDestination.maxDistance || null;

        const toRemove = [];
        this.scene.traverse(e => {
          if (e.userData.toRemove) {
            toRemove.push(e);
            delete e.userData.toRemove;
          }
          if (e.userData.toAdd) {
            if (e.material && e.userData.type != "sprite") {
              e.material.transparent = this.transparentMap[e.id];
            }
            e.visible = true;
            delete e.userData.toAdd;
          }
        }, true);
        this.scene.remove(...toRemove);

        return true;
      } else {
        return false;
      }
    },
    updateHTMLHotspotPosition(e) {
      const d = e.element;
      let { top, left } = this.vector2TopLeft(e.position);
      d.style.top = top + "px";
      d.style.left = left + "px";

      //check if behind camera
      try {
        var target = new THREE.Vector3().copy(e.position);
        var lookAt = new THREE.Vector3(0, 0, -1);
        lookAt.applyQuaternion(this.camera.quaternion);
        var cameraPos = new THREE.Vector3().setFromMatrixPosition(
          this.camera.matrixWorld
        );

        var pos = target.sub(cameraPos);

        let behind = pos.angleTo(lookAt) > Math.PI / 2;
        if (behind) {
          d.style.visibility = "hidden";
        } else {
          d.style.visibility = "visible";
        }
      } catch (e) {
        console.log(e);
      }
    },
    updateHTMLHotspots() {
      this.htmlHotspots.forEach(e => {
        this.updateHTMLHotspotPosition(e);
      });
    },
    render() {
      if (
        this.$listeners &&
        this.$listeners.objectHover &&
        this.conf &&
        !this.conf.disableModelInteraction
      ) {
        const intersection = this.getIntersection(this.mouse);
        this.$emit("objectHover", intersection, this.scene);
      }

      const delta = this.clock.getDelta();

      if (this.cameraDestination.active) {
        this.controls.maxDistance = 1000;
        this.controls.minDistance = 0;
        if (this.moveCameraToDestination(delta)) {
          this.cameraDestination.active = false;
          this.controls.enabled = true;
          if (this.onTransitionEnded) this.onTransitionEnded();
        }
      }

      this.updateHTMLHotspots();

      if (this.mixer) this.mixer.update(delta);

      this.renderer.render(this.scene, this.camera);
    },
    onTransitionEnded: function() {}
  },
  mounted() {}
};
</script>
<style>
</style>