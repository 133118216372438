<template><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" height="32" width="32"><title>{{title}}</title><g stroke-linecap="round" stroke-linejoin="round" stroke-width="2" :fill="_secondaryfill" :stroke="_secondaryfill"><polygon points="19 16 32 24 19 32 19 16" fill="none"></polygon><path d="M6,6H42a4,4,0,0,1,4,4V38a4,4,0,0,1-4,4H6a4,4,0,0,1-4-4V10A4,4,0,0,1,6,6Z" fill="none" :stroke="fill"></path></g></svg></template>

<script>
export default {
	props: {
		title: {
			type: String,
			default: "video player"
		},
		fill: {
			type: String,
			default: "currentColor"
		},
		secondaryfill: {
			type: String
		}
	},
	computed: {
		_secondaryfill: function () {
			return this.secondaryfill || this.fill
		}
	}
}
</script>