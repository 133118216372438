<template>
  <div>
    <contentcard-vue></contentcard-vue>
    <edspace content="Fermi" />
  </div>
</template>
<script>
import ContentcardVue from "@/components/Contentcard.vue";
import Edspace from "../components/edspace.vue";

export default {
  components: {
    ContentcardVue,
    Edspace
  }
};
</script>
<style>
</style>