<template>
  <div id="App">
    <!-- <loadingScreen :isLoading="isLoading" /> -->
    <sidenav></sidenav>
    <navbar-vue></navbar-vue>
    <div class="wrapper" v-if="!isLoading">
      <router-view />
    </div>
  </div>
</template>

<script>
import navbarVue from "@/components/navbar.vue";
import Sidenav from "@/components/Sidenav.vue";
import loadingScreen from "@/components/loadingScreen.vue";
import Edspace from "@/components/edspace.vue";
import json from "@/json/data.json";

export default {
  name: "App",
  data() {
    return {
      myJson: json,
      portraitMode: true
    };
  },
  props: ["isLoading"],
  components: {
    Sidenav,
    // ContentcardVue,
    navbarVue,
    // Edspace,
    // loadingScreen
    // panorama
  },
  methods: {
    getObjects() {
      this.$http.get("@/json/data.json").then(response => {
        console.log(response.body);
        this.lists = response.body.objects;
      });
    },
    setDisplayMode(portraitMode) {
      // var portraitMode = true;
      if (this.onResize) {
        if (window.innerHeight > window.innerWidth) {
          return true;
        } else {
          return false;
        }
      }
      console.log("prueba de portrait" + portraitMode);
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/custom-vars.scss";

.wrapper {
  height: 100%;
}
.raimbow {
  background: linear-gradient(
    90deg,
    rgba(226, 6, 19, 1) 0%,
    rgba(255, 236, 0, 1) 16%,
    rgba(0, 149, 64, 1) 43%,
    rgba(49, 39, 130, 1) 72%,
    rgba(225, 0, 126, 1) 100%
  ) !important;
  min-height: 5px;
}

@-webkit-keyframes slide-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
  }
}
@keyframes slide-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
  }
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
.typewriter {
  animation: tytping steps(40, end);
}
/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@-webkit-keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes slide-out-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
}
@keyframes slide-out-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  animation-delay: 1s;
  transition: all 0.8s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(1000px);
  opacity: 0;
}
.typewriter p {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  white-space: nowrap; /* Keeps the content on a single line */
 /* margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: .15em; /* Adjust as needed */
  animation-delay: 5s;
  animation: 
    typing 1.5s steps(40, end),
    blink-caret .75s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}
</style>
