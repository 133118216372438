import Vue from 'vue';
import Router from 'vue-router';
import home from '@/views/home';
import elettra from '@/views/elettra';
import fermi from '@/views/fermi';
import credits from '@/views/credits';
// import videoEmbed from '@/views/videoEmbed';
//import anelloaccumulatore from '@/views/anello';
import xproomelettra from '@/views/xproomelettra';
//import linac from '@/views/linac';
//import ondulatori from '@/views/ondulatori';
//import xproomfermi from '@/views/xproomfermi';
import immersive from '@/views/immersive';
import tutorial from '@/views/tutview';

Vue.use(Router);

export default new Router({
    routes: [
        {
            path: "/",
            name: "Il Sincrotrone",
            component: home
        },
        {
            path: "/elettra/:id",
            name: "Elettra",
            component: home
        },
        {
            path: "/elettra/",
            id: "elettra",
            name: "Elettra root",
            component: home
        },
        {
            path: "/fermi/",
            name: "Fermi root",
            component: home,
        },
        {
            path: "/fermi/:id",
            name: "Fermi",
            component: home,
        },
        {
            path: "/credits",
            name: "Credits",
            component: credits
        },
        {
            path: "/elettra/xproom",
            name: "Sala Sperimentale Elettra",
            component: xproomelettra
        },
        {
            path: "/tutorial",
            name: "Tutorial",
            component: tutorial
        },
        {
            path: "/immersive/:id",
            name: "Immersive",
            component: immersive
        },
        {
            path: "/immersive/:id/:sphere",
            name: "Immersive",
            component: immersive
        }

    ]
});