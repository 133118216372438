<template>
  <b-sidebar
    id="my-sidebar"
    title="Elettra Sincrotrone Trieste Virtual Visit"
    bg-variant="dark"
    text-variant="light"
    class="sidenav"
    shadow
  >
    <div class="px-3 py-2" v-if="conf">
      <div class="raimbow"></div>
      <router-link class="h1" to="/">{{getText("THE_FACILITIES")}}</router-link>
      <router-link class="h2" to="/elettra">Elettra</router-link>
      <router-link class="h3" to="/elettra/linacbooster">{{getText("LINACBOOSTER")}}</router-link>
      <router-link class="h3" to="/elettra/anelloaccumulatore">{{getText("RING")}}</router-link>
      <router-link class="h3" to="/elettra/xproom">{{getText("XPROOM")}}</router-link>
      <router-link class="h2" to="/fermi">FERMI</router-link>
      <router-link class="h3" to="/fermi/linac">{{getText("LINAC")}}</router-link>
      <router-link class="h3" to="/fermi/salaondulatori">{{getText("UNDULATOR")}}</router-link>
      <router-link class="h3" to="/fermi/xproom">{{getText("XPROOM")}}</router-link>
      <router-link class="h2" to="/credits">Credits</router-link>
    </div>
  </b-sidebar>
  <!-- Use any element to open the sidenav -->
</template>

<script>
import { ConfigurationManager } from "@/assets/js/configuration-manager.js";

export default {
  name: "Contentcard",
  data() {
    return {
      conf: null
    };
  },
  methods: {
    getText: function(key) {
      return ConfigurationManager.getText(key);
    }
  },
  mounted() {
    ConfigurationManager.onLanguageChanged(() => {
      this.conf = null;

      ConfigurationManager.load(conf => {
        setTimeout(() => {
          this.conf = conf;
        }, 0);
      });
    });
    ConfigurationManager.load(conf => {
      this.conf = conf;
    });
  }
};
</script>
<style lang="css">
#my-sidebar {
  background-color: black !important;
}
</style>