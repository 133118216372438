<template><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" height="12" width="12"><title>{{title}}</title><g :fill="_secondaryfill"><path d="M9.854,1.646l-1.5-1.5a.5.5,0,0,0-.708,0l-5.5,5.5a.5.5,0,0,0,0,.708l5.5,5.5a.5.5,0,0,0,.708,0l1.5-1.5a.5.5,0,0,0,0-.708L6.207,6,9.854,2.354A.5.5,0,0,0,9.854,1.646Z" :fill="fill"></path></g></svg></template>

<script>
export default {
	props: {
		title: {
			type: String,
			default: "nav left"
		},
		fill: {
			type: String,
			default: "currentColor"
		},
		secondaryfill: {
			type: String
		}
	},
	computed: {
		_secondaryfill: function () {
			return this.secondaryfill || this.fill
		}
	}
}
</script>